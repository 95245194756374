import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ClientUserService {
  constructor(private httpService: HttpService) { }

  updateUserSessionInfo: any = new BehaviorSubject({});
  validNodeTypes = [
    { node_name: 'mp4', node_type: 'media' },
    { node_name: 'mkv', node_type: 'media' },
    { node_name: 'avi', node_type: 'media' },
    { node_name: 'mov', node_type: 'media' },
    { node_name: 'wmv', node_type: 'media' },
    { node_name: 'png', node_type: 'media' },
    { node_name: 'jpg', node_type: 'media' },
    { node_name: 'jpeg', node_type: 'media' },
    
    // Non-media types
    { node_name: 'zip', node_type: 'non-media' },
    { node_name: 'rar', node_type: 'non-media' },
    { node_name: 'html', node_type: 'non-media' },
    { node_name: 'js', node_type: 'non-media' },
    { node_name: 'json', node_type: 'non-media' },
    { node_name: 'java', node_type: 'non-media' },
    { node_name: 'md', node_type: 'non-media' },
    { node_name: 'pdf', node_type: 'non-media' },
    { node_name: 'txt', node_type: 'non-media' },
    { node_name: 'doc', node_type: 'non-media' },
    { node_name: 'docx', node_type: 'non-media' },
    { node_name: 'xls', node_type: 'non-media' },
    { node_name: 'xlsx', node_type: 'non-media' },
    { node_name: 'wpd', node_type: 'non-media' },
    { node_name: 'csv', node_type: 'non-media' },
    { node_name: 'ics', node_type: 'non-media' },

    // Additional media formats
    { node_name: 'mpeg', node_type: 'media' },
    { node_name: 'mpg', node_type: 'media' },
    { node_name: 'm4v', node_type: 'media' },
    { node_name: '3gp', node_type: 'media' },
    { node_name: '3g2', node_type: 'media' },
    { node_name: 'mxf', node_type: 'media' },
    { node_name: 'mts', node_type: 'media' },
    { node_name: 'm2ts', node_type: 'media' },
    { node_name: 'ts', node_type: 'media' },
    { node_name: 'vob', node_type: 'media' },
    { node_name: 'ogv', node_type: 'media' },
    { node_name: 'ogg', node_type: 'media' },
    { node_name: 'rm', node_type: 'media' },
    { node_name: 'rmvb', node_type: 'media' },
    { node_name: 'asf', node_type: 'media' },
    { node_name: 'dv', node_type: 'media' },
    { node_name: 'f4v', node_type: 'media' },
    { node_name: 'h261', node_type: 'media' },
    { node_name: 'h263', node_type: 'media' },
    { node_name: 'h264', node_type: 'media' },
    { node_name: 'hevc', node_type: 'media' },
    { node_name: 'vp8', node_type: 'media' },
    { node_name: 'vp9', node_type: 'media' },
    { node_name: 'av1', node_type: 'media' },
    { node_name: 'avc', node_type: 'media' },
    { node_name: 'avchd', node_type: 'media' },
    { node_name: 'flv', node_type: 'media' },
    { node_name: 'm2v', node_type: 'media' },
    { node_name: 'm4p', node_type: 'media' },
    { node_name: 'm4v', node_type: 'media' },
    { node_name: 'm2ts', node_type: 'media' },
    { node_name: 'm2', node_type: 'media' },
    { node_name: 'webp', node_type: 'media' },
    { node_name: 'avif', node_type: 'media' },
    { node_name: 'jfif', node_type: 'media' },
    { node_name: 'pjpeg', node_type: 'media' },
    { node_name: 'pjp', node_type: 'media' },
    { node_name: 'svg', node_type: 'media' },

    
];

 isMediaType(nodeType: string): any {
    return this.validNodeTypes.some(type => type.node_name === nodeType && type.node_type === 'media');
}

isNonMediaType(nodeType: string): any {
    return this.validNodeTypes.some(type => type.node_name === nodeType && type.node_type === 'non-media');
}

private iconMap: { [key: string]: string } = {
  mp4: 'mdi-filmstrip',
  mkv: 'mdi-filmstrip',
  avi: 'mdi-filmstrip',
  mov: 'mdi-filmstrip',
  wmv: 'mdi-filmstrip',
  mpeg: 'mdi-filmstrip',
  mpg: 'mdi-filmstrip',
  m4v: 'mdi-filmstrip',
  '3gp': 'mdi-filmstrip',
  '3g2': 'mdi-filmstrip',
  mxf: 'mdi-filmstrip',
  mts: 'mdi-filmstrip',
  m2ts: 'mdi-filmstrip',
  ts: 'mdi-filmstrip',
  vob: 'mdi-filmstrip',
  ogv: 'mdi-filmstrip',
  ogg: 'mdi-filmstrip',
  rm: 'mdi-filmstrip',
  rmvb: 'mdi-filmstrip',
  asf: 'mdi-filmstrip',
  dv: 'mdi-filmstrip',
  f4v: 'mdi-filmstrip',
  h261: 'mdi-filmstrip',
  h263: 'mdi-filmstrip',
  h264: 'mdi-filmstrip',
  hevc: 'mdi-filmstrip',
  vp8: 'mdi-filmstrip',
  vp9: 'mdi-filmstrip',
  av1: 'mdi-filmstrip',
  avc: 'mdi-filmstrip',
  avchd: 'mdi-filmstrip',
  flv: 'mdi-filmstrip',
  m2v: 'mdi-filmstrip',
  m4p: 'mdi-filmstrip',
  m2: 'mdi-filmstrip',
  zip: 'mdi-folder-zip-outline',
  rar: 'mdi-folder-zip-outline',
  html: 'mdi-language-html5',
  js: 'mdi-nodejs',
  json: 'mdi-code-json',
  java: 'mdi-language-java',
  md: 'mdi-markdown',
  pdf: 'mdi-file-pdf-box',
  png: 'mdi-file-image',
  jpg: 'mdi-file-image',
  jpeg: 'mdi-file-image',
  webp: 'mdi-file-image',
  avif: 'mdi-file-image',
  jfif: 'mdi-file-image',
  pjpeg: 'mdi-file-image',
  pjp: 'mdi-file-image',
  svg: 'mdi-file-image',
  txt: 'mdi-file-document-outline',
  doc: 'mdi-file-document-outline',
  docx: 'mdi-file-document-outline',
  xls: 'mdi-file-excel-outline',
  xlsx: 'mdi-file-excel-outline',
  wpd: 'mdi-file-word-box-outline',
  csv: 'mdi-file-delimited',
  ics: 'mdi-calendar',
};

getIcon(nodeType: string): string {
  return this.iconMap[nodeType] || 'mdi-file-outline';
}


  Login(login_info) {
    return this.httpService.user_login(login_info).pipe(map(res => res));
  }
  UserList(form_info) {
    return this.httpService.user_list(form_info).pipe(map(res => res));
  }
  RoleList() {
    return this.httpService.role_list().pipe(map(res => res));
  }
  AddNewUser(form_info) {
    return this.httpService.invite_new_user(form_info).pipe(map(res => res));
  }
  EditUser(form_info) {
    return this.httpService.EditUser(form_info).pipe(map(res => res));
  }
  verifyPasswordSetToken(token_info) {
    return this.httpService.verifyPasswordSetToken(token_info)
      .pipe(map(
        res => res
      ));
  }
  verifyPasswordResetToken(token_info) {
    return this.httpService.verifyPasswordResetToken(token_info)
      .pipe(map(
        res => res
      ));
  }
  setPassword(password_details) {
    return this.httpService.setPassword(password_details)
      .pipe(map(
        res => res
      ));
  }
  resetPassword(password_details) {
    return this.httpService.resetPassword(password_details)
      .pipe(map(
        res => res
      ));
  }
  fetchNodesList(nodes_data) {
    return this.httpService.fetchNodesList(nodes_data)
      .pipe(map(
        res => res
      ));
  }
  fetchWorkspaces() {
    return this.httpService.fetchWorkspaces()
      .pipe(map(
        res => res
      ));
  }
  fetchWorkspaceChildNodesList(node_id) {
    return this.httpService.fetchWorkspaceChildNodesList(node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchParentChildNodes(node_id) {
    return this.httpService.fetchParentChildNodes(node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchParentChildNodesList(hashed_node_id) {
    return this.httpService.fetchParentChildNodesList(hashed_node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchRootNode() {
    return this.httpService.fetchRootNode()
      .pipe(map(
        res => res
      ));
  }
  inviteAccept(acceptInviteData) {
    return this.httpService.inviteAccept(acceptInviteData)
      .pipe(map(
        res => res
      ));
  }
  createFolder(folderData) {
    return this.httpService.createFolder(folderData)
      .pipe(map(
        res => res
      ));
  }
  moveNode(folderData) {
    return this.httpService.moveNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  renameNode(folderData) {
    return this.httpService.renameNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  fetchUserInfo(user_id) {
    return this.httpService.fetchUserInfo(user_id)
      .pipe(map(
        res => res
      ));
  }

  getFailedFilesInformation(aspera_transfer_id) {
    return this.httpService.getFailedFilesInformation(aspera_transfer_id)
      .pipe(map(
        res => res
      ));

  }

  deleteNode(folderData) {
    return this.httpService.deleteNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  clientLogo(info) {
    return this.httpService.clientLogo(info)
      .pipe(map(
        res => res
      ));
  }
  intiateForgotPassword(info) {
    return this.httpService.intiateForgotPassword(info)
      .pipe(map(
        res => res
      ));
  }
  fetchListofRestrictedUserAccess(info) {
    return this.httpService.fetchListofRestrictedUserAccess(info)
      .pipe(map(
        res => res
      ));
  }
  createRestrictedAccess(info) {
    return this.httpService.createRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  removeRestrictedAccess(info) {
    return this.httpService.removeRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  updateRestrictedAccess(info) {
    return this.httpService.updateRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  fetchSharedWithMeNodes() {
    return this.httpService.fetchSharedWithMeNodes()
      .pipe(map(
        res => res
      ));
  }
  fetchRestrictedAccessChildNodesList(info) {
    return this.httpService.fetchRestrictedAccessChildNodesList(info)
      .pipe(map(
        res => res
      ));
  }
  resend_invite(formData: FormData) {
    return this.httpService.resend_invite(formData).pipe(map(res => res));
  }
  cancel_invite(formData: FormData) {
    return this.httpService.cancel_invite(formData).pipe(map(res => res));
  }

  EditProfile(form_info) {
    return this.httpService.EditProfile(form_info).pipe(map(res => res));
  }


  getUserSessionInfo() {

    return this.updateUserSessionInfo;

  }

  setUserSessionInfo(sessionInfo) {

    this.updateUserSessionInfo.next({
      'session_info': sessionInfo
    });

  }

  deleteUser(form_info) {
    return this.httpService.deleteUser(form_info).pipe(map(res => res));
  }

  unBlockUser(form_info) {
    return this.httpService.unBlockUser(form_info).pipe(map(res => res));
  }

  fetchUserProfileInfo() {
    return this.httpService.fetchUserProfileInfo().pipe(map(res => res));
  }
  EditUserProfile(form_info) {
    return this.httpService.EditUserProfile(form_info).pipe(map(res => res));
  }

  getPreviewNodeInfo(form_info) {
    return this.httpService.getPreviewNodeInfo(form_info).pipe(map(res => res));
  }
}
